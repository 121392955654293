import { Component, Vue, Watch } from 'vue-property-decorator';
import { SidemenuItem } from '@/components/sidemenu/Sidemenu';

@Component<HourAdministration>({})
export default class HourAdministration extends Vue {
  public $pageTitle = 'Urenadministratie';

  protected isLoading = true;

  protected items: SidemenuItem[] = [
    {
      title: 'Tijd schrijven',
      link: '/urenadministratie',
      icon: {
        key: 'access_time',
      },
      visible: ! this.$store.state.isServiceOrganization && this.$store.state.hasTimeModule,
    },
    {
      title: 'Urenoverzicht',
      link: '/urenadministratie/mijn-urenoverzicht',
      icon: {
        key: 'reorder',
      },
      visible: ! this.$store.state.isServiceOrganization && this.$store.state.hasTimeModule,
    },
    {
      title: 'Uren verwerken',
      link: '/urenadministratie/uren-verwerken',
      icon: {
        key: 'done_all',
      },
      visible: ! this.$store.state.isServiceOrganization && this.$store.state.hasTimeModule && this.$store.state.Auth.is_rockefeller,
    },
    {
      title: 'Declaratie specificaties',
      link: '/urenadministratie/declaratie-specificaties',
      icon: {
        key: 'assignment',
      },
      visible: ! this.$store.state.isServiceOrganization && this.$store.state.hasTimeModule && this.$store.state.Auth.is_rockefeller,
    },
    {
      title: 'Interne specificaties Bureau',
      link: '/urenadministratie/interne-specificaties',
      icon: {
        key: 'assignment_ind',
      },
      visible: ! this.$store.state.isServiceOrganization && this.$store.state.hasTimeModule && this.$store.state.Auth.is_rockefeller,
    },
    {
      title: 'Aannemersvariant',
      link: '/urenadministratie/aannemersvariant',
      icon: {
        key: 'cloud_download',
      },
      visible: ! this.$store.state.isServiceOrganization && this.$store.state.hasTimeModule && this.$store.state.Auth.is_rockefeller,
    },

  ];

  protected specificationsItems: SidemenuItem[] = [
    {
      title: 'Specificatie checker',
      link: '/urenadministratie/specificatie-checker',
      icon: {
        key: 'checklist_rtl',
      },
      visible: ! this.$store.state.isServiceOrganization,
    },
    {
      title: 'Factuur specificaties uploaden',
      link: '/urenadministratie/specificaties-uploaden',
      icon: {
        key: 'cloud_circle',
      },
      visible: ! this.$store.state.isServiceOrganization,
    },
    {
      title: 'Ingediende specificaties',
      link: '/urenadministratie/specificaties-indienen',
      icon: {
        key: 'publish',
      },
      visible: ! this.$store.state.isServiceOrganization,
    },
    {
      title: 'Ingediende specificaties',
      link: '/urenadministratie/ingediende-specificaties',
      icon: {
        key: 'summarize',
      },
      visible: this.$store.state.isServiceOrganization,
    },
    {
      title: 'Afgekeurde specificaties',
      link: '/urenadministratie/afgekeurde-specificaties',
      icon: {
        key: 'dangerous',
      },
      visible: true,
    },
    {
      title: 'Goedgekeurde specificaties',
      link: '/urenadministratie/goedgekeurde-specificaties',
      icon: {
        key: 'task',
      },
      visible: true,
    },
  ];

  protected settingsItems: SidemenuItem[] = [
    {
      title: 'Tarieven',
      link: '/urenadministratie/tarieven',
      icon: {
        key: 'euro',
      },
      visible: this.$store.state.Auth.is_rockefeller && (this.$store.state.hasTimeModule || this.$store.state.isServiceOrganization),
    },
    {
      title: 'Instellingen',
      link: '/urenadministratie/instellingen',
      icon: {
        key: 'settings_applications',
      },
      visible: ! this.$store.state.isServiceOrganization && this.$store.state.hasTimeModule && this.$store.state.Auth.is_rockefeller,
    },
  ];

  public mounted() {
    this.initialize();
  }

  protected hasVisibleItem(items: SidemenuItem[]): boolean {
    return items.some((item: SidemenuItem) => item.visible);
  }

  protected initialize() {
    this.emitBreadcrumb();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Overzicht' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
